/**
 * Support: web-master72@yandex.ru;
 * Gallery, Services, Menu labels - #fae7d7;
 * Links, Buttons - #F5D76E;
/* ---------------------------------------------- /*
 * General styles
/* ---------------------------------------------- */

@import url(https://fonts.googleapis.com/css?family=Dancing+Script:700);
@import url(https://fonts.googleapis.com/css?family=Crimson+Text:400,600,700,400italic);

body {
  overflow-x: hidden;
  font: 400 16px/1.8 "Crimson Text", serif;
  color: #666;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  color: #f5d76e;
}

a:hover {
  text-decoration: none;
  color: #999;
}

/* ---------------------------------------------- /*
 * Transition elsements
/* ---------------------------------------------- */

.form-control,
.navbar-custom {
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

a,
.btn,
.navbar a {
  -webkit-transition: all 0.125s ease-in-out 0s;
  -moz-transition: all 0.125s ease-in-out 0s;
  -ms-transition: all 0.125s ease-in-out 0s;
  -o-transition: all 0.125s ease-in-out 0s;
  transition: all 0.125s ease-in-out 0s;
}

/* ---------------------------------------------- /*
 * Reset box-shadow
/* ---------------------------------------------- */

.btn,
.form-control,
.form-control:hover,
.form-control:focus,
.navbar-custom .dropdown-menu {
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* ---------------------------------------------- /*
 * Reset border-radius
/* ---------------------------------------------- */

.btn,
.label,
.alert,
.progress,
.form-control,
.modal-content,
.panel-heading,
.panel-group .panel,
.nav-tabs > li > a,
.nav-pills > li > a {
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -o-border-radius: 0px;
  border-radius: 0px;
}

/* ---------------------------------------------- /*
 * Typography
/* ---------------------------------------------- */

h1,
h2,
h3,
h4,
h5,
h6 {
  font: 700 54px/1.2 "Crimson Text", serif;
  margin: 0 0 20px;
  color: #000;
}

h2 {
  font-size: 48px;
  margin: 0 0 18px;
}

h3 {
  font-size: 40px;
  margin: 0 0 15px;
}

h4 {
  font-size: 20px;
  margin: 0 0 10px;
}

h5 {
  font-size: 18px;
  font-weight: 400;
  margin: 0 0 8px;
}

h6 {
  font-size: 16px;
  font-weight: 400;
  margin: 0 0 5px;
}

blockquote {
  font: 700 50px/1.2 "Dancing Script", cursive;
  margin: 0 0 20px;
  padding: 0;
  border: 0;
}

blockquote small,
blockquote footer,
blockquote .small {
  display: block;
  font-size: 40%;
  line-height: 1.42857143;
  color: #999;
}

.intro-title,
.module-title,
.callout-text,
.iconbox-title {
  font: 700 28px/1.2 "Dancing Script", cursive;
  margin: 0 0 18px;
}

.module-title,
.callout-text {
  font-size: 50px;
}

.module-subtitle {
  font: 400 16px/1.2 "Crimson Text", serif;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #a7a9ac;
  margin: 0;
}

.intro-title {
  font-size: 55px;
}

/* ---------------------------------------------- /*
 * Alerts style
/* ---------------------------------------------- */

.alert {
  border: 0;
}

.help-block {
  font-size: 14px;
  color: #a94442;
}

.help-block ul {
  list-style: none;
  padding: 0 15px;
  margin: 0;
}

/* ---------------------------------------------- /*
 * Progress bars
/* ---------------------------------------------- */

.progress,
.progress-bar {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.progress-bar {
  background-color: #f5d76e;
}

/* ---------------------------------------------- /*
 * Tabs
/* ---------------------------------------------- */

.nav-pills,
.nav-tabs {
  margin-bottom: 20px;
}

.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
  background: #f5d76e;
}

/* ---------------------------------------------- /*
 * Panels
/* ---------------------------------------------- */

.panel {
  border: 0;
}

.panel-body {
  background: #f5f5f5;
}

.panel-group .panel-heading + .panel-collapse > .panel-body {
  border: 0;
}

/* ---------------------------------------------- /*
 * Modal
/* ---------------------------------------------- */

.modal-content {
  border: 0;
}

/* ---------------------------------------------- /*
 * Labels
/* ---------------------------------------------- */

.label {
  font: 400 italic 10px/1.8 "Crimson Text", serif;
  padding: 0.2em 0.6em 0.2em;
  letter-spacing: 1px;
  font-size: 60%;
}

.label-custom-1 {
  background: #f5d76e;
}

.label-custom-2 {
  background: #000;
}

/* ---------------------------------------------- /*
 * Inputs styles
/* ---------------------------------------------- */

.form-control {
  border: 0;
  background: transparent;
  border-bottom: 1px dotted #ddd;
  font-size: 16px;
  padding: 0;
}

.form-control:focus {
  border-color: #444444;
}

.input-lg,
.form-horizontal .form-group-lg .form-control {
  height: 38px;
  font-size: 18px;
}

.input-sm,
.form-horizontal .form-group-sm .form-control {
  font-size: 11px;
  height: 30px;
}

textarea.form-control {
  padding: 15px 0;
}

.form-control-black {
  border: 1px solid #ddd;
}

.form-control-black:focus {
  border-color: #444444;
}

/* ---------------------------------------------- /*
 * Custom button style
/* ---------------------------------------------- */

.btn {
  letter-spacing: 1px;
  font-size: 16px;
  padding: 8px 26px;
}

.btn:hover,
.btn:focus,
.btn:active,
.btn.active {
  outline: inherit !important;
}

.btn-custom-1 {
  background: transparent;
  border: 1px solid #000;
  color: #000;
}

.btn-custom-1:hover,
.btn-custom-1:focus,
.btn-custom-1:active,
.btn-custom-1.active {
  background: #000;
  border-color: #000;
  color: #fff;
}

.btn-custom-2 {
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
}

.btn-custom-2:hover,
.btn-custom-2:focus,
.btn-custom-2:active,
.btn-custom-2.active {
  background: #fff;
  border-color: #fff;
  color: #000;
}

.btn-custom-3 {
  background: transparent;
  border: 1px solid #000;
  color: #000;
}

.btn-custom-3:hover,
.btn-custom-3:focus,
.btn-custom-3:active,
.btn-custom-3.active {
  background: #f5d76e;
  border-color: #f5d76e;
  color: #fff;
}

.btn-custom-4 {
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
}

.btn-custom-4:hover,
.btn-custom-4:focus,
.btn-custom-4:active,
.btn-custom-4.active {
  background: #f5d76e;
  border-color: #f5d76e;
  color: #fff;
}

.btn-custom-5 {
  background: #000;
  border: 1px solid #000;
  color: #fff;
}

.btn-custom-5:hover,
.btn-custom-5:focus,
.btn-custom-5:active,
.btn-custom-5.active {
  background: #fff;
  border-color: #fff;
  color: #000;
}

/* Button size */

.btn-lg,
.btn-group-lg > .btn {
  padding: 12px 30px;
  font-size: 18px;
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 6px 25px;
  font-size: 11px;
}

.btn-xs,
.btn-group-xs > .btn {
  padding: 5px 25px;
  font-size: 10px;
}

.btn .icon-before {
  margin-right: 6px;
}

.btn .icon-after {
  margin-left: 6px;
}

.btn .icon-after,
.btn .icon-before {
  font-size: 12px;
}

.btn-list .btn {
  margin-bottom: 5px;
}

/* ---------------------------------------------- /*
 * Main
/* ---------------------------------------------- */

.module {
  position: relative;
  background: #fff;
  padding: 20px 0;
}

.module-gray {
  background: #f5f5f5;
}

.module-dark {
  background: #0e0e0e;
}

.callout,
.callout h1,
.callout h2,
.callout h3,
.callout h4,
.callout h5,
.callout h6,
.module-dark,
.module-dark h1,
.module-dark h2,
.module-dark h3,
.module-dark h4,
.module-dark h5,
.module-dark h6,
.intro-module,
.intro-module h1,
.intro-module h2,
.intro-module h3,
.intro-module h4,
.intro-module h5,
.intro-module h6 {
  color: #fff;
}

.module-header {
  text-align: center;
  margin: 0 0 60px;
}

.long-up {
  margin-top: 60px;
}

.long-up-2x {
  margin-top: 80px;
}

.long-down {
  margin-bottom: 60px;
}

.text-center {
  text-align: center;
}

.devider {
  text-align: center;
  margin: 60px 0 0;
}

.js-hovered {
  cursor: pointer;
}

.js-fade {
  opacity: 0.65;
}

.vjs-control-bar {
  visibility: hidden;
  display: none;
}

.parallax {
  background-attachment: fixed;
  background-position: 50% 0;
}

.bg-dark-alfa-30::after {
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
  content: "";
  z-index: -1;
}

.bg-dark-alfa-60::after {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
  content: "";
  z-index: -1;
}

.bg-dark-alfa-90::after {
  background-color: rgba(0, 0, 0, 0.9);
  position: absolute;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
  content: "";
  z-index: -1;
}

/* ---------------------------------------------- /*
 * Callout
/* ---------------------------------------------- */

.callout {
  background-color: #222;
  position: relative;
  text-align: center;
  padding: 180px 0;
  z-index: 1;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

#callout-one {
  /* background-image: url(../images/home-2.jpg); */
}

#callout-two {
  /* background-image: url(../images/home-3.jpg); */
}

#callout-three {
  /* background-image: url(../images/home-4.jpg); */
}

/* ---------------------------------------------- /*
 * Home
/* ---------------------------------------------- */

.module-image {
  background-color: #222;
  /* background-image: url(../images/home-1.jpg); */
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 0;
}

.video-background {
  background-color: #000;
}

.intro {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  text-align: center;
  -webkit-transform: translate(0%, -50%);
  -moz-transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
  -o-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
  padding: 0 15px;
}

/* ---------------------------------------------- /*
 * Menu
/* ---------------------------------------------- */

.menu {
  border-bottom: 1px dotted #ddd;
  padding: 0 0 10px;
  margin: 0 0 20px;
}

.menu-classic img {
  margin: 0 0 10px;
}

.menu-title,
.menu-price {
  font-size: 16px;
  margin: 0;
}

.menu-detail {
  font-size: 14px;
}

.menu-price-detail {
  position: relative;
  text-align: left;
}

.menu-label {
  background: #fae7d7;
  position: relative;
  display: inline-block;
  padding: 2px 10px 0px 10px;
  font-family: "Crimson Text", serif;
  letter-spacing: 1px;
  font-style: italic;
  font-size: 13px;
  float: right;
  top: 5px;
}

/* ---------------------------------------------- /*
 * Servise
/* ---------------------------------------------- */

.iconbox {
  background: #fae7d7;
  text-align: center;
  padding: 15px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.iconbox-body {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 25px 10px;
}

.iconbox-icon {
  color: #000;
  font-size: 32px;
  margin-bottom: 25px;
}

.iconbox-title {
  color: #000;
  margin: 0 0 15px;
  padding: 0;
}

.iconbox-desc {
  font-size: 15px;
}

/* ---------------------------------------------- /*
 * Testimonials
/* ---------------------------------------------- */

.testimonial-text {
  text-align: center;
  margin: 0 0 60px;
}

.testimonial-avatar {
  text-align: right;
}

.testimonial-avatar img {
  height: 84px;
  width: 84px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
}

.testimonial-info {
  padding-top: 11px;
  text-align: left;
}

.stars {
  font-size: 12px;
  color: #f1c40f;
}

.stars i {
  margin: 0 2px 0 0;
}

.star-off {
  color: #999;
}

/* ---------------------------------------------- /*
 * Gallery
/* ---------------------------------------------- */

.margin-0 {
  margin: 0 !important;
}

.position-relative {
  position: relative;
}

.side-image {
  position: absolute;
  background: #fae7d7;
  padding: 30px;
  height: 100%;
}

.vertical-body {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: table;
  height: 100%;
  text-align: center;
}

.vertical {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  padding: 30px;
}

.gallery-no-padding {
  background: #fff;
}

.gallery-no-padding > .row > .col-sm-4 {
  padding: 0;
}

.gallery-detail {
  border-bottom: 1px dotted #ddd;
  text-align: center;
  margin: 15px 0 20px;
  padding: 0 0 10px;
}

.gallery-title {
  font-size: 16px;
  margin: 0 0 5px;
}

/* ---------------------------------------------- /*
 * Menu Classic, Gallery Overlay
/* ---------------------------------------------- */

.overlay {
  position: relative;
  margin: 0;
}

.overlay a {
  position: absolute;
  text-indent: 200%;
  font-size: 0;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: 10;
}

.overlay img {
  display: block;
  height: auto;
  width: 100%;
}

.overlay figcaption {
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  text-align: center;
  padding: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  top: 0px;
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.overlay .caption-inner {
  position: absolute;
  margin-top: -25px;
  width: 100%;
  top: 50%;
  left: 0;
}

.overlay:hover figcaption {
  opacity: 1;
}

.overlay:hover span,
.overlay:hover .overlay-icon i {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}

.overlay-icon {
  border: 2px solid #fff;
  line-height: 48px;
  font-size: 16px;
  margin: 0 auto;
  height: 50px;
  width: 50px;
  color: #fff;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -o-border-radius: 50px;
  border-radius: 50px;
}

.overlay-icon i,
.overlay-icon span {
  color: #f5d76e;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

/* ---------------------------------------------- /*
 * Twitter
/* ---------------------------------------------- */

.interact a {
  margin-right: 10px;
}

/* ---------------------------------------------- /*
 * Map, Contact and Ajax
/* ---------------------------------------------- */

#map-section {
  position: relative;
  width: 100%;
}

#map {
  height: 100%;
  width: 100%;
}

.ajax-response {
  text-align: center;
  padding: 20px;
}

/* ---------------------------------------------- /*
 * Footer
/* ---------------------------------------------- */

#footer {
  background: #000;
  text-align: center;
  padding: 10px 0;
  font-size: 14px;
  color: #fff;
}

.copyright {
  margin: 0;
}

.social-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.social-links li {
  display: inline-block;
  margin: 0 5px;
}

/* ---------------------------------------------- /*
 * Navigation
/* ---------------------------------------------- */

.navbar-custom {
  border: 0;
  border-radius: 0;
  margin: 0;
}

.navbar-custom,
.navbar-custom .dropdown-menu {
  background: rgba(0, 0, 0, 0.9);
  padding: 0;
}

.navbar-custom .navbar-nav {
  margin: 1px -15px 0;
}

.navbar-custom .navbar-brand,
.navbar-custom .navbar-nav > li > a,
.navbar-custom .navbar-nav .dropdown-menu > li > a {
  font-size: 15px;
  letter-spacing: 1px;
  color: #fff;
}

.navbar-custom .navbar-brand {
  font-family: "Dancing Script", cursive;
  font-weight: 700;
  font-size: 32px;
}

.navbar-custom .nav .open > a,
.navbar-custom .nav li a:focus,
.navbar-custom .dropdown-menu > li > a:focus {
  background: none;
  outline: 0;
  color: #fff;
}

.navbar-custom li > a {
  position: relative;
}
.navbar-custom .dropdown-toggle:after {
  position: absolute;
  display: block;
  right: 1px;
  top: 50%;
  margin-top: -5px;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 12px;
  content: "\f107";
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.navbar-custom .nav li a:hover,
.navbar-custom .navbar-nav > li.active a {
  background: none;
  outline: 0;
  color: #f5d76e;
}

.navbar-custom .dropdown-menu {
  border: 0;
  border-top: 2px solid #f5d76e;
  border-radius: 0;
}

.navbar-custom .dropdown-menu > li > a {
  padding: 10px 20px;
}

.navbar-custom .dropdown-menu > li > a:hover {
  background: rgba(0, 0, 0, 0.9);
}

.navbar-custom .navbar-toggle .icon-bar {
  background: #fff;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu li {
  border-left: 1px solid rgba(32, 31, 31, 0.9);
}

.dropdown-submenu > .dropdown-menu {
  display: block;
  left: 100%;
  top: 0;
  right: inherit;
  margin-top: -2px;
}

.dropdown-submenu > .dropdown-menu {
  visibility: hidden;
  opacity: 0;
}

.dropdown-submenu:hover > .dropdown-menu {
  visibility: visible;
  opacity: 1;
}

.dropdown-submenu > a {
  position: relative;
}

.dropdown-submenu > a:after {
  position: absolute;
  display: block;
  right: 7px;
  top: 50%;
  margin-top: -6px;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 12px;
  content: "\f105";
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dropdown-submenu:hover > a:after {
  border-left-color: #fff;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
}

.left-side .dropdown-submenu > .dropdown-menu {
  left: inherit;
  right: 100%;
}

.left-side .dropdown-submenu li {
  border-left: 0;
  border-right: 1px solid rgba(32, 31, 31, 0.9);
}

/* --------------------------------------------------------- */

@media (min-width: 767px) {
  .navbar-transparent {
    background: transparent;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

@media (max-width: 767px) {
  .navbar-custom .nav li a:hover {
    background: rgba(0, 0, 0, 0.9);
  }

  .navbar-nav .open .dropdown-menu > li > a,
  .navbar-nav .open .dropdown-menu .dropdown-header {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 20px;
  }

  .navbar-custom li a {
    border-bottom: 1px solid rgba(32, 31, 31, 0.9) !important;
  }

  .dropdown-submenu > .dropdown-menu {
    display: none;
    visibility: visible;
    opacity: 1;
  }

  .dropdown-submenu > .dropdown-open {
    display: block;
  }

  .navbar-nav .open .dropdown-submenu > .dropdown-menu li > a {
    padding-left: 40px;
  }

  .dropdown-submenu > a:after,
  .navbar-custom .dropdown-toggle:after {
    right: 7px;
    content: "\f107";
  }

  .dropdown-submenu > a.angle-up:after,
  .navbar-custom .dropdown-toggle.angle-up:after {
    content: "\f106";
  }
}

/* ---------------------------------------------- /*
 * Scroll to top
/* ---------------------------------------------- */

.scroll-up {
  position: fixed;
  display: none;
  bottom: 1em;
  right: 1em;
  z-index: 999;
}

.scroll-up a {
  background: #fff;
  display: block;
  height: 28px;
  width: 28px;
  text-align: center;
  line-height: 28px;
  font-size: 14px;
  color: #000;
  opacity: 0.7;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
}

.scroll-up a:hover,
.scroll-up a:active {
  opacity: 1;
  color: #000;
}
/* ---------------------------------------------- /*
 * Mouse animate icon
/* ---------------------------------------------- */

.mouse-icon {
  position: absolute;
  left: 50%;
  bottom: 40px;
  border: 2px solid #fff;
  border-radius: 16px;
  height: 40px;
  width: 24px;
  margin-left: -15px;
  display: block;
  z-index: 10;
}

.mouse-icon .wheel {
  -webkit-animation-name: drop;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-delay: 0s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-play-state: running;
  animation-name: drop;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-play-state: running;
}

.mouse-icon .wheel {
  position: relative;
  border-radius: 10px;
  background: #fff;
  width: 2px;
  height: 6px;
  top: 4px;
  margin-left: auto;
  margin-right: auto;
}

@-webkit-keyframes drop {
  0% {
    top: 5px;
    opacity: 0;
  }
  30% {
    top: 10px;
    opacity: 1;
  }
  100% {
    top: 25px;
    opacity: 0;
  }
}

@keyframes drop {
  0% {
    top: 5px;
    opacity: 0;
  }
  30% {
    top: 10px;
    opacity: 1;
  }
  100% {
    top: 25px;
    opacity: 0;
  }
}

/* ---------------------------------------------- /*
 * Preloader
/* ---------------------------------------------- */

#preloader {
  background: #fff;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;
}

#status,
.status-mes {
  /* background-image: url(../images/preloader1.gif); */
  background-position: center;
  background-repeat: no-repeat;
  height: 200px;
  left: 50%;
  margin: -100px 0 0 -100px;
  position: absolute;
  top: 50%;
  width: 200px;
}

.status-mes {
  background: none;
  left: 0;
  margin: 0;
  text-align: center;
  top: 65%;
}

/* ---------------------------------------------- /*
 * Media
/* ---------------------------------------------- */

@media (max-width: 991px) {
  .side-image {
    position: relative;
    height: auto;
  }

  .navbar-custom .navbar-brand,
  .navbar-custom .navbar-nav > li > a,
  .navbar-custom .navbar-nav .dropdown-menu > li > a {
    letter-spacing: 0;
  }
}

@media (max-width: 767px) {
  .menu-title,
  .menu-detail,
  .menu-price-detail {
    text-align: center;
  }

  .menu-label {
    float: none;
  }

  .mouse-icon {
    display: none;
  }

  .iconbox {
    margin: 10px 0;
  }

  .testimonial-avatar,
  .testimonial-info {
    text-align: center;
  }
}

@media (max-width: 568px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font: 700 38px/1.2 "Crimson Text", serif;
    margin: 0 0 20px;
    color: #000;
  }

  h2 {
    font-size: 30px;
    margin: 0 0 18px;
  }

  h3 {
    font-size: 24px;
    margin: 0 0 15px;
  }

  h4 {
    font-size: 20px;
    margin: 0 0 10px;
  }

  h5 {
    font-size: 16px;
    font-weight: 400;
    margin: 0 0 8px;
  }

  h6 {
    font-size: 16px;
    font-weight: 400;
    margin: 0 0 5px;
  }

  blockquote {
    font: 700 38px/1.2 "Dancing Script", cursive;
    margin: 0 0 20px;
    padding: 0;
    border: 0;
  }

  .intro-title,
  .module-title,
  .callout-text,
  .iconbox-title {
    font: 700 38px/1.2 "Dancing Script", cursive;
    margin: 0 0 18px;
  }

  .iconbox-title {
    font-size: 24px;
  }

  .module-subtitle {
    font: 400 10px/1.2 "Libre Baskerville", serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #a7a9ac;
    margin: 0;
  }
}

/* ---------------------------------------------- /*
 * Icons page
/* ---------------------------------------------- */

.sli-examples .item:hover {
  background: #f0f0f0;
  color: #000;
}

.sli-examples .item {
  display: block;
  width: 33%;
  float: left;
  padding: 0;
  border: 1px solid #e5e5e5;
  margin: -1px 0 0 -1px;
  font-size: 13px;
}

.sli-examples .item > span {
  display: inline-block;
  margin-right: 5px;
  min-width: 70px;
  min-height: 70px;
  border-right: 1px solid #f1f1f1;
  line-height: 70px;
  text-align: center;
  font-size: 32px;
}

.fa-examples > div {
  padding: 0;
  border: 1px solid #e5e5e5;
  margin: -1px 0 0 -1px;
  font-size: 13px;
}

.fa-examples > div > i {
  display: inline-block;
  margin-right: 5px;
  min-width: 40px;
  min-height: 40px;
  border-right: 1px solid #f1f1f1;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
}
